import { HTMLAttributes} from 'react'
import './user.sass'
import Avatar from "../Avatar/Avatar";
import { toast } from 'react-toastify'

interface IStaffItemProps extends HTMLAttributes<HTMLDivElement> {
  staff?: any
}

const UserItem: React.FC<IStaffItemProps> = ({
  staff = {},
  ...props
}: IStaffItemProps) => {
  
  return (
    <div className="staff-item" {...props}>
      <div className="left">
        <Avatar size={56} name={staff?.name} url={staff.avatar} />
        <div className="details">
          <div className="name">{staff.name}</div>
          <div className="bottom">
            <span>{staff.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserItem